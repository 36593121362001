<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <defs>
     <mask id="ldio-f3du99a7t7e-mask">
       <circle cx="50" cy="50" r="20" stroke="#fff" stroke-linecap="round" stroke-dasharray="94.24777960769379 31.41592653589793" stroke-width="8" transform="rotate(298.209 50 50)">
         <animateTransform attributeName="transform" type="rotate" values="0 50 50;360 50 50" times="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
       </circle>
     </mask>
    </defs>
    <g mask="url(#ldio-f3du99a7t7e-mask)"><rect x="21.5" y="0" width="10.333333333333334" height="100" fill="#95c020">
      <animate attributeName="fill" values="#95c020;#6db971;#668db2;#705fa6;#a34693;#c61d72" times="0;0.2;0.4;0.6;0.8;1" dur="1s" repeatCount="indefinite" begin="-0.8333333333333334s"></animate>
    </rect><rect x="30.833333333333336" y="0" width="10.333333333333334" height="100" fill="#6db971">
      <animate attributeName="fill" values="#95c020;#6db971;#668db2;#705fa6;#a34693;#c61d72" times="0;0.2;0.4;0.6;0.8;1" dur="1s" repeatCount="indefinite" begin="-0.6666666666666666s"></animate>
    </rect><rect x="40.16666666666667" y="0" width="10.333333333333334" height="100" fill="#668db2">
      <animate attributeName="fill" values="#95c020;#6db971;#668db2;#705fa6;#a34693;#c61d72" times="0;0.2;0.4;0.6;0.8;1" dur="1s" repeatCount="indefinite" begin="-0.5s"></animate>
    </rect><rect x="49.5" y="0" width="10.333333333333334" height="100" fill="#705fa6">
      <animate attributeName="fill" values="#95c020;#6db971;#668db2;#705fa6;#a34693;#c61d72" times="0;0.2;0.4;0.6;0.8;1" dur="1s" repeatCount="indefinite" begin="-0.3333333333333333s"></animate>
    </rect><rect x="58.833333333333336" y="0" width="10.333333333333334" height="100" fill="#a34693">
      <animate attributeName="fill" values="#95c020;#6db971;#668db2;#705fa6;#a34693;#c61d72" times="0;0.2;0.4;0.6;0.8;1" dur="1s" repeatCount="indefinite" begin="-0.16666666666666666s"></animate>
    </rect><rect x="68.16666666666667" y="0" width="10.333333333333334" height="100" fill="#c61d72">
      <animate attributeName="fill" values="#95c020;#6db971;#668db2;#705fa6;#a34693;#c61d72" times="0;0.2;0.4;0.6;0.8;1" dur="1s" repeatCount="indefinite" begin="0s"></animate>
    </rect></g>
</svg>