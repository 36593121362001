import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    Output,
    EventEmitter, AfterViewInit
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-search',
    templateUrl: 'search.component.html',
    styleUrls: ['search.component.scss']
})

export class SearchComponent implements OnInit, AfterViewInit {
    @ViewChild('search') searchComponent: ElementRef;

    @Output() valueChange = new EventEmitter<string>();

    searchValue = new FormControl('');

    constructor(
        private hostElement: ElementRef
    ) { }

    ngOnInit() {
        this.searchValue.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged()
        ).subscribe(
            (value) => {
                this.valueChange.emit(value);
            }
        );
    }

    ngAfterViewInit() {
        const styles = getComputedStyle(this.searchComponent.nativeElement);
        this.hostElement.nativeElement.style.height = styles.height;
        this.hostElement.nativeElement.style.width = styles.width;
    }

    onFocus() {
        (this.searchComponent.nativeElement as HTMLElement).classList.add('full-width');
    }

    onFocusOut() {
        (this.searchComponent.nativeElement as HTMLElement).classList.remove('full-width');
    }
}
