import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@shared/services/auth.service';
import { CockpitGuard } from '@shared/guards/cockpit.guard';
import { CustomerResolver } from '@shared/services/customer.resolver';

const routes: Routes = [
    {
        path: 'customers',
        loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'measurements',
        loadChildren: () => import('./modules/measurement/measurement.module').then(m => m.MeasurementModule),
        canActivate: [AuthGuardService]
    },
    {
        path: ':customerId/teams',
        loadChildren: () => import('./modules/team/team.module').then(m => m.TeamsModule),
        canActivate: [AuthGuardService],
        resolve: {
            customer: CustomerResolver
        }
    },
    {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'cockpit',
        loadChildren: () => import('./modules/cockpit/cockpit.module').then(m => m.CockpitModule),
        canActivate: [AuthGuardService, CockpitGuard]
    },
    { path: 'teams', redirectTo: '/customers', pathMatch: 'full' },
    { path: '',   redirectTo: '/cockpit', pathMatch: 'full' },
    { path: '**', redirectTo: '/cockpit', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled', enableTracing: false, relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
