import { Router } from '@angular/router';
import {
    Directive,
    Input,
    ElementRef,
    Renderer2,
    HostListener,
    OnDestroy,
} from '@angular/core';

@Directive({
    selector: '[appTooltip]',
})
export class TooltipDirective implements OnDestroy {
    @Input('appTooltip') tooltipTitle: string;
    @Input() placement: string;
    @Input() italic: boolean;
    @Input() styling: string;
    tooltip: HTMLElement;

    offset = 5;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private router: Router
    ) {}

    @HostListener('mouseenter') onMouseEnter() {
        if (!this.tooltip && this.tooltipTitle) {
            this.show();
        }
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this.tooltip && this.tooltipTitle) {
            this.hide();
        }
    }

    show() {
        this.create();
        this.setPosition();
        this.renderer.addClass(this.tooltip, 'tooltip-show');
    }

    hide() {
        this.renderer.removeClass(this.tooltip, 'tooltip-show');
        this.renderer.removeChild(document.body, this.tooltip);
        this.tooltip = null;
    }

    create() {
        this.tooltip = this.renderer.createElement('span');

        this.renderer.appendChild(
            this.tooltip,
            this.renderer.createText(this.tooltipTitle)
        );

        this.renderer.appendChild(document.body, this.tooltip);

        this.renderer.addClass(this.tooltip, 'tooltip');
        this.renderer.addClass(this.tooltip, this.styling);
        this.renderer.addClass(this.tooltip, `tooltip-${this.placement}`);

        if (this.italic) {
            this.renderer.setStyle(this.tooltip, 'font-style', `italic`);
        }
    }

    setPosition() {
        const hostPos = this.el.nativeElement.getBoundingClientRect();
        const tooltipPos = this.tooltip.getBoundingClientRect();
        const scrollPos =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0;

        let top;
        let left;


        switch (this.placement) {
            case 'bottom':
                top = hostPos.bottom + this.offset;
                left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
                break;
            case 'left':
                top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
                left = hostPos.left - tooltipPos.width - this.offset;
                break;
            case 'right':
                top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
                left = hostPos.right + this.offset;
                break;
                // 'top' is default case
            default:
                top = hostPos.top - tooltipPos.height - this.offset;
                left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
                break;
        }

        this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
        this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
    }

    ngOnDestroy() {
        if (this.tooltip) {
            this.hide();
        }
    }
}
