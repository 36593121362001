import { Component, OnInit } from '@angular/core';
import { BaseModalComponent } from '@shared/components/base-modal/base-modal.component';
import { ModalService } from '@shared/services/modal.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MeasurementTemplate } from '@shared/models/measurement-template';
import { MeasurementService } from '@shared/services/measurement.service';
import { LoaderService } from '@shared/services/loader.service';

@Component({
    selector: 'app-edit-question-modal',
    templateUrl: 'edit-question-modal.component.html',
    styleUrls: ['edit-question-modal.component.scss'],
})

export class EditQuestionModalComponent extends BaseModalComponent implements OnInit {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public question: any = {};
    public model: FormGroup;
    public template: MeasurementTemplate;

    constructor(
        private modalService: ModalService,
        private measurementService: MeasurementService,
        private fb: FormBuilder,
        private loaderService: LoaderService,
    ) {
        super();
    }

    get type() {
        return this.question.type === 'open_question' ? 'Open vraag' : 'Schaal vraag';
    }

    get isReversed() {
        return this.model.get('is_reverse').value;
    }

    closeModal(value) {
        this.loaderService.stop();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this._afterClosed.next(value);
    }

    async ngOnInit() {
        super.ngOnInit();
        await this.modalService.modalData
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data) => {
                this.question = data;
            });

        if (this.question.type === 'open_question') {
            this.createOpenQuestionGroup();
        } else {
            this.createScaleQuestionGroup();
        }

        this.measurementService.activeMeasurementTemplate
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                template => this.template = template
            );
    }

    createOpenQuestionGroup() {
        this.model = this.fb.group({
            name: [this.question.name, Validators.required]
        });
    }

    createScaleQuestionGroup() {
        this.model = this.fb.group({
            name: [this.question.name, Validators.required],
            is_reverse: [this.question.is_reverse],
            text_positive: [this.question.text_positive, Validators.required],
            text_negative: [this.question.text_negative, Validators.required]
        });

        if (this.question.is_reverse) {
            this.model.addControl('text_output', new FormControl(this.question.text_output, Validators.required));
        }

        this.model.get('is_reverse').valueChanges
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                isReverse => {
                    if (isReverse === true) {
                        if (this.question.text_output && this.question.text_output.length) {
                            this.model.addControl('text_output', new FormControl(this.question.text_output, Validators.required));
                        } else {
                            this.model.addControl('text_output', new FormControl(this.question.name, Validators.required));
                        }
                    } else {
                        if (this.model.contains('text_output')) {
                            this.model.removeControl('text_output');
                        }
                    }
                }
            );
    }

    canBeNegative() {
        return !(this.template.type === 'small' && this.template.team_id);
    }

    isScale() {
        return this.question.type === 'scale_question';
    }

    saveDisabled() {
        return this.model.invalid;
    }

    save() {
        this.loaderService.start();
        if (this.question.type === 'open_question') {
            this.measurementService.updateOpenQuestion(this.model.value, this.question.id)
                .subscribe(
                    () => this.closeModal(this.model.value),
                    (error) => this.handleError(error)
                );
        } else {
            this.measurementService.updateScaleQuestion(this.model.value, this.question.id)
                .subscribe(
                    () => this.closeModal(this.model.value),
                    (error) => this.handleError(error)
                );
        }
    }

    handleError(error) {
        console.error(error);
        this.loaderService.stop();
    }
}
