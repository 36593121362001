import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class CloudflareInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (environment.cloudflare_id && environment.cloudflare_secret) {
            const newReq = req.clone({
                headers: req.headers
                    .set('CF-Access-Client-Id', environment.cloudflare_id)
                    .set('CF-Access-Client-Secret', environment.cloudflare_secret)
            });

            return next.handle(newReq);
        }

        return next.handle(req);
    }
}
