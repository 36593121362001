import { Component } from '@angular/core';
import { BaseModalComponent } from '@shared/components/base-modal/base-modal.component';

@Component({
    selector: 'app-stop-measurement-modal',
    templateUrl: 'stop-measurement-modal.component.html',
    styleUrls: ['stop-measurement-modal.component.scss'],
})

export class StopMeasurementModalComponent extends BaseModalComponent {

    constructor() {
        super();
    }


    closeModal(value) {
        this._afterClosed.next(value);
    }

}
