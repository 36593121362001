import { OpenQuestion } from '@shared/models/open-question';
import { ScaleQuestion } from '@shared/models/scale-question';
import { User } from '@shared/models/user';
import {Subscription} from '@shared/models/subscription';

export class ChartPermissions {
    is_quad_open: boolean;
    is_polar_open: boolean;
    is_highest_lowest_open: boolean;
    is_most_least_open: boolean;
    is_open_questions_open: boolean;
}

export class MeasurementTemplate {

    public id: number = null;
    public type: string = null;
    public name: string = null;
    public description: string = null;
    public category: string = null;
    public open_questions?: OpenQuestion[] = null;
    public scale_questions?: ScaleQuestion[] = null;
    public open_questions_count = 0;
    public scale_questions_count = 0;
    public created_at: string = null;
    public creator: User = null;
    public updater: User = null;
    public updated_at: string = null;
    public users_count: number = null;
    public repeat: boolean = null;
    public repeat_times: number = null;
    public start_date: string = null;
    public end_date: string = null;
    public team_session_date: string = null;
    public is_editable_by_coordinator: boolean = null;
    public measurement_template_ids: number[] = [];
    public is_editable: boolean = null;
    public is_stoppable: boolean = null;
    public related_templates: MeasurementTemplate[] = [];
    public related: MeasurementTemplate[] = [];
    public dates: Date[] = [];
    public start_notification_sent: boolean = null;
    public team_id: number = null;
    public is_questions_editable: boolean = null;
    public tps: string;
    public chart: null;
    public chart_permissions: ChartPermissions;
    public subscriptions: Subscription[] = [];
    public subscription_ids: number[] = [];

    public next_repeated: MeasurementTemplate;
    public next_date?: Date;
    public last_repeated: MeasurementTemplate;

    getTotalQuestionsCount(): number {
        return this.open_questions_count + this.scale_questions_count;
    }


    sortByNext(a: MeasurementTemplate, b: MeasurementTemplate) {
        return a.next_date > b.next_date ? -1 : 1;
    }

    // Can not call this method for some reason?
    getNextEndDate(): string {
        if (this.next_repeated) {
            return this.next_repeated.team_session_date;
        }

        if (this.last_repeated) {
            return this.last_repeated.team_session_date;
        }

        return this.team_session_date;
    }

}
