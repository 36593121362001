<div #ref (click)="showDropdown()">
    <input
        name="name"
        type="hidden"
        class="form-control"
        readonly
        [placeholder]="placeholder"
        [(ngModel)]="value"
        (click)="showDropdown()"
    />
    <span>{{ displayValue }}</span>
    <app-dropdown [reference]="ref" #dropdownComp>
        <div class="options" [style.background-color]="background">
            <div *ngFor="let option of options; index as i; trackBy:trackByValue" class="option" (click)="onOptionSelect(option[valueKey])">{{ option[displayKey] }}</div>
        </div>
    </app-dropdown>
</div>
