import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { switchMap } from 'rxjs/operators';

@Pipe({
    name: 'secure'
})
export class SecurePipe implements PipeTransform {

    constructor(
        private http: HttpClient,
        private domSanitizer: DomSanitizer
    ) { }

    transform(url: string) {
        return this.http
            .get(url, {
                responseType: 'blob'
            })
            .pipe(
                switchMap(blob => {
                    return new Observable(observer => {
                        observer.next('/assets/images/loader.svg');
                        const reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = () => {
                            observer.next(
                                this.domSanitizer.bypassSecurityTrustUrl(
                                    reader.result as string
                                )
                            );
                        };
                    });
                })
            );
    }
}
