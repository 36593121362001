import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { DropdownComponent } from '@shared/components/dropdown/dropdown.component';

@Component({
    selector: 'app-dropdown-button',
    templateUrl: 'dropdown-button.component.html',
    styleUrls: ['dropdown-button.component.scss']
})

export class DropdownButtonComponent implements OnInit {
    @Input() options: {label: string, value: string}[];
    @Output() changed = new EventEmitter<string>();

    @ViewChild(DropdownComponent)
    public dropdown: DropdownComponent;

    constructor() { }

    ngOnInit() { }

    public showDropdown() {
        this.dropdown.show();
    }

    public hideDropdown() {
        this.dropdown.hide();
    }

    public onOptionSelect(option) {
        this.changed.emit(option);
        this.hideDropdown();
    }
}

