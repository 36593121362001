<div class="custom-select">
    <ul>
        <li class="option" [class.disabled]="disable" *ngFor="let option of options"
            [ngClass]="{selected: isSelected(option)}"
            (click)="onOptionClick(option)"
        >
            <span class="circle-wrapper">
                <span class="circle"></span>
            </span>
            {{ getOptionLabel(option) }}
        </li>
    </ul>
</div>
