import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerService } from './customer.service';
import { Customer } from 'projects/client/src/app/shared/models/Measurement';
import { LoaderService } from './loader.service';

interface CustomerResponse {
    customer: Customer;
}

@Injectable({ providedIn: 'root' })
export class CustomerResolver implements Resolve<CustomerResponse> {

    constructor(
        private customerService: CustomerService,
        private loaderService: LoaderService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const customerId = route.paramMap.get('customerId');

        return this.loaderService.spin(this.customerService.getCustomer(customerId)
            .pipe(
                map(response => response.customer)
            ));
    }
}
