import { Expose } from 'class-transformer';

export interface CustomerPermissions {
    quad: boolean;
    polar: boolean;
    activity: boolean;
    measurement: boolean;
}

export class Customer {
    public id: number = null;
    public name: string = null;
    public color: string = null;
    public logo: any = null;
    public teams_count: number = null;
    public measurement_frequency_big: number = null;
    public measurement_frequency_small: number = null;
    public users_count: number = null;
    public permissions: CustomerPermissions = null;
    public subscription_type = '';
    public show_teams_rule: boolean;
    public show_users_rule: boolean;
    public show_cockpit_rule: boolean;
    public archived_at: string = null;

    hasSubscriptionType(subscriptionType: string): boolean {
        return this.subscription_type === subscriptionType;
    }

    hasOneOfSubscriptionTypes(subscriptionTypes: string[]): boolean {
        return subscriptionTypes.includes(this.subscription_type);
    }
}
