import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public me: BehaviorSubject<User> = new BehaviorSubject(null);
    public user: BehaviorSubject<User> = new BehaviorSubject(null);

    constructor(
        private httpClient: HttpClient
    ) { }
}
