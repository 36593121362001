import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { User } from '@shared/models/user';
import { CockpitService } from '@app/modules/cockpit/services/cockpit.service';

@Injectable({
    providedIn: 'root'
})
export class CockpitGuard implements CanActivate {
    private user: User;

    constructor(
        private authService: AuthService,
        private router: Router,
        private cockpitService: CockpitService
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {

        this.authService.user.subscribe((user) => {
            this.user = user;
        });

        if (this.cockpitService.customerId) {
            return true;
        }

        this.router.navigateByUrl('/customers');

        return false;
    }
}
