import { Component } from '@angular/core';
import { BaseModalComponent } from '@shared/components/base-modal/base-modal.component';

@Component({
    selector: 'app-prompt-modal',
    templateUrl: 'prompt-modal.component.html',
    styleUrls: ['prompt-modal.component.scss'],
})

export class PromptModalComponent extends BaseModalComponent {

    constructor() {
        super();
    }


    closeModal(value) {
        this._afterClosed.next(value);
    }

}
