import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import { AuthInterceptor } from '@shared/interceptors/auth.interceptor';
import { CloudflareInterceptor } from '@shared/interceptors/cloudflare.interceptor';
import { LoaderService } from '@shared/services/loader.service';
import { CustomerService } from '@shared/services/customer.service';
import { AppRoutingModule } from './app-routing.module';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ChartsModule } from 'ng2-charts';
import localeNl from '@angular/common/locales/nl';
import { QuadChartResolver } from '@shared/services/quad-chart.resolver';
import { HighestLowestResolver } from '@shared/services/highest-lowest.resolver';
import { SpiderChartResolver } from '@shared/services/spider-chart.resolver';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateMeasurementResolver } from '@shared/services/create-measurement.resolver';
import { AuthService, AuthGuardService } from '@shared/services/auth.service';
import { BaseChartComponent } from './base-chart/base-chart.component';
import { BaseComponent } from './base/base.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
registerLocaleData(localeNl);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        CommonModule,
        NoopAnimationsModule,
        MatDatepickerModule,
        MatFormFieldModule,
        ChartsModule,
        SwiperModule,
        NgbModule,
        TranslateModule.forRoot({
            defaultLanguage: 'nl_NL',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        AppComponent,
        BaseChartComponent,
        BaseComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CloudflareInterceptor, multi: true },
        LoaderService,
        CustomerService,
        { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true} },
        { provide: LOCALE_ID, useValue: 'nl-NL' },
        QuadChartResolver,
        HighestLowestResolver,
        SpiderChartResolver,
        CreateMeasurementResolver,
        AuthService,
        AuthGuardService
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {}
