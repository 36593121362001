<div class="teams-rights-select">
    <ul>
        <li class="team" *ngFor="let team of teams">
            <div class="d-flex label"
                 [class.disabled]="disable"
                 [ngClass]="{selected: isTeamSelected(team)}"
                 (click)="onTeamClick(team)"
            >
                <span class="circle-wrapper">
                    <span class="circle"></span>
                </span>
                <span>{{ getTeamLabel(team) }}</span>
            </div>
            <ul class="rights" *ngIf="isTeamSelected(team)">
                <li class="right" [ngClass]="{selected: isRightSelected(team, right)}" *ngFor="let right of rights" (click)="onRightClick(team, right)">
                    <span class="circle-wrapper">
                        <span class="circle"></span>
                    </span>
                    <span>{{ right.label }}</span>
                </li>
            </ul>
        </li>
    </ul>
</div>
