import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Customer } from '../models/customer';
import { environment } from '@environments/environment';
import { map, tap } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { LoaderService } from './loader.service';
import { User } from '../models/user';
import { Team } from '../models/team';
import {Subscription} from '@shared/models/subscription';

export interface UsersResponse {
    skip: number;
    take: number;
    teams: Team[];
    total: number;
    users: User[];
    coordinatorEmails: string[];
}

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    private url = `${environment.api_url}/customers`;
    public customers: BehaviorSubject<Customer[]> = new BehaviorSubject([]);
    public isEditing: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public activeColor: BehaviorSubject<string> = new BehaviorSubject('');
    public users: BehaviorSubject<User[]> = new BehaviorSubject([]);
    public totalUsers: BehaviorSubject<number> = new BehaviorSubject(0);
    public customer: BehaviorSubject<Customer> = new BehaviorSubject(null);
    public teams: BehaviorSubject<Team[]> = new BehaviorSubject([]);
    public subscribtionTypes: BehaviorSubject<Subscription[]> = new BehaviorSubject([]);

    constructor(
        private http: HttpClient,
        private loaderService: LoaderService
    ) { }

    public fetch() {
        this.loaderService.start();
        this.http.get<{customers: Customer[]}>(this.url)
            .pipe(
                map(response => response.customers),
                map(customer => plainToClass(Customer, customer))
            )
            .subscribe(
                (customers) => {
                    this.customers.next(customers);
                    this.loaderService.stop();
                },
                () => { this.loaderService.stop(); }
            );
    }

    public fetchSubscribtionTypes() {
        return this.http.get<{subscribtion_types: Subscription[]}>(`${environment.api_url}/customers/subscribtion_types`)
            .pipe(
                map(response => response.subscribtion_types),
                map(subscribtion_types => plainToClass(Subscription, subscribtion_types)),
                tap(response => this.subscribtionTypes.next(response))
            );
    }

    public getCustomer(id, replace = true) {
        return this.http.get<{customer: Customer}>(`${this.url}/${id}`)
            .pipe(
                tap(response => {
                    if (replace) {
                        this.customer.next(response.customer)
                    }
                })
            );
    }

    public getCustomerImage(id): Observable<Blob> {
        return this.http.get(`${this.url}/${id}/logo`, { responseType: 'blob' });
    }

    public save(formData, customerId?) {
        if (customerId) {
            return this.http.post(`${this.url}/${customerId}?_method=PUT`, formData);
        } else {
            return this.http.post(this.url, formData);
        }
    }

    public saveTeam(model, customerId) {
        if (model.id) {
            return this.http.put(`${environment.api_url}/teams/${model.id}`, model);
        } else {
            return this.http.post(`${this.url}/${customerId}/teams`, model);
        }
    }

    public getUsers(customerId: number, queryParams: HttpParams): Observable<UsersResponse> {
        return this.http.get<UsersResponse>(`${environment.api_url}/customers/${customerId}/users`, { params: queryParams })
            .pipe(
                map(response => {
                    response.users = response.users.map(user => plainToClass(User, user));
                    response.teams = response.teams.map(team => plainToClass(Team, team));
                    return response;
                }),
                tap(response => this.users.next(response.users)),
                tap(response => this.totalUsers.next(response.total)),
                tap(response => this.teams.next(response.teams))
            );
    }

    public storeUser(customerId: number, user: User) {
        return this.http.post(`${environment.api_url}/customers/${customerId}/users`, user);
    }

    public updateUser(customerId: number, userId: number, user: User) {
        return this.http.put(`${environment.api_url}/customers/${customerId}/users/${userId}`, user);
    }

    public deleteUser(customerId, userId) {
        return this.http.delete(`${environment.api_url}/customers/${customerId}/users/${userId}`);
    }

    public downloadData(customer: Customer) {
        return this.http.get(`${environment.api_url}/customers/${customer.id}/download_data`, { responseType: 'blob' });
    }

    public archive(id) {
        return this.http.delete(`${this.url}/${id}/archive`);
    }

    public unarchive(id) {
        return this.http.post(`${this.url}/${id}/unarchive`, {});
    }
}
