export class ScaleQuestion {

    public id: number = null;
    public name: string = null;
    public text_negative: string = null;
    public text_positive: string = null;
    public is_reverse: boolean = null;
    public text_output: string = null;
    public type = 'scale_question';
    public theme_name?: string = null;
    public theme_id: number = null;
    public theme_category?: string = null;
}
