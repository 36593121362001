import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import {AuthService} from '@shared/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken) {
            req = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${accessToken}`)
            });
        }

        return next.handle(req).pipe(
            tap(
                () => {},
                (error: HttpErrorResponse) => {
                    const currentUrl = this.router.url;

                    if (error.status === 401 && !currentUrl.includes('reset-password')) {
                        this.authService.logout();
                    }
                }
            )
        );
    }
}
