import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MeasurementService } from './measurement.service';
import { CustomerService } from './customer.service';
import { TeamService } from './team.service';
import { Observable, of, forkJoin } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { ColorService } from './color.service';

interface OpenQuestionsResponse {
    open_questions: {
        name: string,
        answers: string[],
        color?: string,
    }[];
}

interface OpenQuestionsData {
    customer;
    team;
    measurement;
    data: {
        open_questions: {
            name: string,
            answers: string[],
            color?: string,
        }[];
    };
}


@Injectable({providedIn: 'root'})
export class CreateMeasurementResolver implements Resolve<OpenQuestionsData> {

    constructor(
        private measurementService: MeasurementService,
        private customerService: CustomerService,
        private teamService: TeamService,
        private colorService: ColorService,
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const customerId = route.paramMap.get('customerId');
        const teamId = route.paramMap.get('teamId');
        const templateId = route.paramMap.get('templateId');
        const customer = this.customerService.customer.getValue();
        const team = this.teamService.activeTeam.getValue();
        const measurement = this.measurementService.activeMeasurementTemplate.getValue();

        return of({customerId, teamId, templateId}).pipe(
            mergeMap(
                (data) => {
                    return forkJoin({
                        customer: (!customer || String(customer.id)  !== customerId) && customerId !== null  ? this.customerService.getCustomer(data.customerId) : [{
                            customer,
                        }],
                        team: !team || String(team.id)  !== teamId ? this.teamService.getTeam(data.teamId) : [
                            team,
                        ],
                        measurement: !measurement || String(measurement.id)  !== templateId ? this.measurementService.getTemplate(data.templateId) : [
                            measurement,
                        ],
                        data: this.measurementService.getTemplateData(data.templateId),
                    });
                }
            )
        );
    }
}
