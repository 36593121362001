import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { MeasurementTemplate } from '@shared/models/measurement-template';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, of } from 'rxjs';
import { LoaderService } from '@shared/services/loader.service';
import { OpenQuestion } from '@shared/models/open-question';
import { ScaleQuestion } from '@shared/models/scale-question';
import { Team } from '@shared/models/team';

@Injectable({
    providedIn: 'root'
})
export class MeasurementService {
    private url = `${environment.api_url}/measurement-templates`;

    public measurementTemplates: BehaviorSubject<MeasurementTemplate[]> = new BehaviorSubject([]);
    public isEditing: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public activeMeasurementTemplate: BehaviorSubject<MeasurementTemplate> = new BehaviorSubject(null);
    public themeState: BehaviorSubject<any> = new BehaviorSubject(null);
    public activeQuestion: BehaviorSubject<any> = new BehaviorSubject(null);
    public questions: BehaviorSubject<any> = new BehaviorSubject([]);

    constructor(
        private http: HttpClient,
        private loaderService: LoaderService
    ) { }

    public fetch(customer_id?: number, as_resource: boolean = false) {
        this.loaderService.start();
        const url = customer_id ? `${this.url}?customer_id=${customer_id}&as_resource=${as_resource}` : this.url;
        this.http.get<{measurement_templates: MeasurementTemplate[]}>(url)
            .pipe(
                map(response => response.measurement_templates),
                map(measurementTemplate => plainToClass(MeasurementTemplate, measurementTemplate))
            )
            .subscribe(
                (measurementTemplates) => {
                    this.measurementTemplates.next(measurementTemplates);
                    this.loaderService.stop();
                }
            );
    }

    public deleteMeasurementTemplate(templateId) {
        return this.http.delete(`${this.url}/${templateId}`);
    }

    public sortByNext(a: MeasurementTemplate, b: MeasurementTemplate) {
        return a.next_date > b.next_date ? -1 : 1;
    }

    public getTemplate(templateId) {
        this.loaderService.start();
        return this.http.get<{measurement_template: MeasurementTemplate}>(`${this.url}/${templateId}`)
            .pipe(
                map(response => {
                    this.activeMeasurementTemplate.next(plainToClass(MeasurementTemplate, response.measurement_template));
                    return response.measurement_template;
                }),
                map(measurementTemplate => plainToClass(MeasurementTemplate, measurementTemplate))
            );
    }

    public getTemplateData(templateId) {
        this.loaderService.start();

        return this.http.get<{measurement_template: MeasurementTemplate}>(`${this.url}/${templateId}/data`);
    }

    public save(model) {
        if (model.id) {
            if (model.type === 'theme') {
                return this.http.put(`${this.url}/${model.id}/theme`, model);
            } else {
                return this.http.put(`${this.url}/${model.id}/standard`, model);
            }
        }

        return this.http.post(this.url, model);
    }

    public storeSmall(model, team) {
        return this.http.post(`${this.url}/${team.id}/small`, model);
    }

    public stop(model) {
        if (model.id) {
            if (model.type === 'small') {
                return this.http.put(`${this.url}/${model.id}/small/stop`, []);
            }
        }
    }

    public getQuestions(measurementTemplateId) {
        return this.http.get(`${this.url}/${measurementTemplateId}/questions`);
    }

    public storeScaleQuestion(question) {
        const templateId = this.activeMeasurementTemplate.getValue().id;
        return this.http.post<{question: ScaleQuestion}>(`${this.url}/${templateId}/scale-question`, question)
                        .pipe(
                            map(response => response.question),
                            map(data => plainToClass(ScaleQuestion, data))
                        );
    }

    public storeOpenQuestion(question) {
        const templateId = this.activeMeasurementTemplate.getValue().id;
        return this.http.post<{question: OpenQuestion}>(`${this.url}/${templateId}/open-question`, question)
                        .pipe(
                            map(response => response.question),
                            map(data => plainToClass(OpenQuestion, data))
                        );
    }

    public updateScaleQuestion(model, id) {
        return this.http.put(`${environment.api_url}/scale-questions/${id}`, model);
    }

    public updateOpenQuestion(model, id) {
        return this.http.put(`${environment.api_url}/open-questions/${id}`, model);
    }

    public updateName(model) {
        return this.http.put(`${this.url}/${model.id}`, model);
    }

    public deleteQuestion(question) {
        if (question.type === 'open_question') {
            return this.http.delete(`${environment.api_url}/open-questions/${question.id}`);
        } else {
            return this.http.delete(`${environment.api_url}/scale-questions/${question.id}`);
        }
    }

    public getResults(teamId) {
        return this.http.get(`${environment.api_url}/teams/${teamId}/measurements`);
    }

    public getSpiderChartData(templateId) {
        return this.http.get(`${this.url}/${templateId}/charts/spider-web`);
    }

    public getHighestLowestData(templateId) {
        return this.http.get(`${this.url}/${templateId}/charts/highest-lowest`);
    }

    public getOpenQuestionData(templateId) {
        return this.http.get(`${this.url}/${templateId}/charts/open-questions`);
    }

    public getQuadChartData(templateId) {
        return this.http.get(`${this.url}/${templateId}/charts/quad`);
    }

    public getUnanimityChartData(templateId) {
        return this.http.get(`${this.url}/${templateId}/charts/unanimity`);
    }

    public getTrendChartData(templateId) {
        return this.http.get(`${this.url}/${templateId}/charts/trend`);
    }

    public getIndividualSmallChartData(templateId, date) {
        return this.http.get(`${this.url}/${templateId}/charts/individual-answers/${date}`);
    }

    public updateThemeScores(templateId, data) {
        return this.http.put(`${this.url}/${templateId}/theme-scores`, data);
    }

    public updateChartPermissions(templateId, data) {
        return this.http.put(`${this.url}/${templateId}/charts`, data);
    }

    setDiscussed(templateId, date: Date, discussed: boolean) {
        return this.http.put(`${this.url}/${templateId}/charts/individual-answers/${date}`, {
            discussed,
        });
    }

    public updateThemeState(zero, goal) {
        const currentState = this.themeState.value;
        currentState.zeroMeasurement = zero;
        currentState.goal = goal;
        this.themeState.next(currentState);
    }
}
