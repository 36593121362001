<div class="permissions-select">
    <div class="form-group">
        <label>{{title}}</label>
    </div>
    <ul>
        <li class="permission" *ngFor="let option of options">
            <div class="d-flex label"
                 [class.selected]="option === current"
                 (click)="onOptionSelect(option)"
            >
                <span class="circle-wrapper">
                    <span class="circle"></span>
                </span>
                <span class="key">
                    {{option}}
                </span>
            </div>
        </li>
    </ul>
</div>
