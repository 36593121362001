import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
    public currentRoute: string;
    public ngUnsubscribe: Subject<void> = new Subject<void>();
    public sidePanelOpen = false;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
    ) {
    }

    ngOnInit(): void {
        this.currentRoute = this.router.url.split('(')[0];

        this.openSidePanel();
    }

    openSidePanel() {
        this.sidePanelOpen = true;
        this.router.navigate(
            [
                {
                    outlets: {
                        sidebar: ['detail'],
                    },
                },
            ],
            {
                relativeTo: this.route.parent,
                skipLocationChange: true,
            }
        );
    }

}
