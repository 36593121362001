import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Customer} from '@shared/models/customer';
import {Team} from '@shared/models/team';
import {MeasurementTemplate} from '@shared/models/measurement-template';
import {CustomerService} from '@shared/services/customer.service';
import {MeasurementService} from '@shared/services/measurement.service';
import {TeamService} from '@shared/services/team.service';

@Component({
    selector: 'app-base-chart',
    templateUrl: './base-chart.component.html',
    styleUrls: ['./base-chart.component.scss']
})
export class BaseChartComponent implements OnInit {
    public currentRoute: string;
    public ngUnsubscribe: Subject<void> = new Subject<void>();
    public customer: Customer;
    public team: Team;
    public measurement: MeasurementTemplate;

    public measurementId: number;
    public teamId: number;
    public customerId: number;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public customerService: CustomerService,
        public measurementService: MeasurementService,
        public teamService: TeamService,
    ) {
    }

    ngOnInit(): void {
        this.currentRoute = this.router.url;
        this.route.paramMap.subscribe(params => {
            this.customerId = Number(params.get('customerId'));
            this.teamId = Number(params.get('teamId'));
            this.measurementId = Number(params.get('templateId'));

            this.customerService.getCustomer(this.customerId)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(data => {
                    if (data && data.customer) {
                        this.customer = data.customer;
                    }
                });

            this.teamService.getTeam(this.teamId)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(data => {
                    if (data) {
                        this.team = data;
                    }
                });

            this.measurementService.getTemplate(this.measurementId)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(data => {
                    if (data) {
                        this.measurement = data;
                        this.getChartData();
                        this.openSidePanel();
                    }
                });
        });
    }

    getChartData() {
        // TODO: implement this method in child component
    }

    openSidePanel() {
        this.router.navigate(
            [
                {
                    outlets: {
                        sidebar: ['detail'],
                    },
                },
            ],
            {
                relativeTo: this.route.parent,
                skipLocationChange: true,
            }
        );
    }

    goToReports() {
        if (this.customer) {
            this.router.navigateByUrl(
                `${this.customer.id}/teams/${this.team.id}/reports`
            );
        } else {
            this.router.navigateByUrl(
                `teams/${this.team.id}/reports`
            );
        }
    }

}
