
<div class="logout-component">
    <div class="logo-box">
        <div>
            <img id="yolo" class="customer-logo" alt="logo" [src]="logo">
            <span class="icon icon-logout" (click)="logout()"></span>
        </div>
        <p>{{ user?.name}}</p>
    </div>
</div>
