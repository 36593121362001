import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { CustomerService } from '@shared/services/customer.service';
import { Subject } from 'rxjs';
import { Customer } from '@shared/models/customer';


@Component({
    selector: 'app-permissions-select',
    templateUrl: 'permissions-select.component.html',
    styleUrls: ['permissions-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PermissionsSelectComponent),
            multi: true
        }
    ]
})
export class PermissionsSelectComponent implements OnInit, ControlValueAccessor, OnChanges {
    @Input() permissions: any;
    @Input() customer: Customer;
    @Input() title = '';
    @Input() selectAllEnabled = false;
    @Input() allSelected = true;
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    labels = [

    ];

    public onModelChange = (val) => {
        const key = val.key;
        this.permissions[key] = val.value;
    }

    public onModelTouched = () => {
    }

    keepOrder = (a, b) => {
        return a;
    }

    hasStartSubscription() {
        return this.customer.subscription_type === 'Start';
    }

    constructor(private customerService: CustomerService) {

    }

    writeValue(value: any): void {
        this.permissions = value;
    }

    registerOnChange(fn): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn): void {
        this.onModelTouched = fn;
    }

    onPermissionClick(permission) {
        if (this.disabledByKey(permission.key)) {
            return;
        }

        permission.value = !permission.value;
        this.onModelChange(permission);
        this.allSelected = true;
        for (const key in this.permissions) {
            if (this.permissions.hasOwnProperty(key)) {
                if (!this.permissions[key]) {
                    this.allSelected = false;
                    break;
                }
            }
        }
    }

    labelByKey(permission) {
        if (permission.key) {
            return this.labels.find(label => label.key === permission.key)?.label;
        }
    }

    disabledByKey(key) {
        return this.labels.find(label => label.key === key)?.disabled;
    }

    async setLabels() {
        if (!this.customer) {
            await this.customerService.customer
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((customer) => {
                    this.customer = customer;
                });
        }

        this.labels = [
            {
                key: 'activity',
                label: 'Ontwikkelactiviteit',
                disabled: false,
            },
            {
                key: 'measurement',
                label: 'Metingenoverzicht',
                disabled: false,
            },
            {
                key: 'quad',
                label: 'Matrix',
                disabled: this.hasStartSubscription(),
            },
            {
                key: 'polar',
                label: 'Radar',
                disabled: false,
            },
            {
                key: 'is_quad_open',
                label: 'Matrix',
                disabled: this.hasStartSubscription(),
            },
            {
                key: 'is_polar_open',
                label: 'Radar',
                disabled: false,
            },
            {
                key: 'is_highest_lowest_open',
                label: 'Hoogste & Laagste',
                disabled: false,
            },
            {
                key: 'is_most_least_open',
                label: 'Overeenkomsten & Verschillen',
                disabled: this.hasStartSubscription(),
            },
            {
                key: 'is_open_questions_open',
                label: 'Open vragen',
                disabled: false,
            },
            {
                key: 'heartbeat',
                label: 'Hartslagmonitor',
                disabled: false
            }
        ];
    }

    ngOnInit() {
        this.setLabels();

        this.setAllSelected();
    }

    private setAllSelected() {
        this.allSelected = true;
        for (const key in this.permissions) {
            if (this.permissions.hasOwnProperty(key)) {
                if (!this.permissions[key]) {
                    this.allSelected = false;
                    break;
                }
            }
        }
    }

    selectAll() {
        this.allSelected = !this.allSelected;
        for (const key in this.permissions) {
            if (this.permissions.hasOwnProperty(key)) {
                if (this.disabledByKey(key) === false) {
                    this.permissions[key] = this.allSelected;
                }
            }
        }

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.permissions) {
            this.setAllSelected();
        }
    }
}
