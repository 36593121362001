<div class="prompt">
    <div class="modal-header">Meting stoppen?<span class="icon icon-x" (click)="closeModal(false)"></span></div>
    <div class="modal-body">
        Weet je zeker dat je deze Hartslagmeting wilt stoppen? Dit is niet terug te draaien.
    </div>
    <div class="modal-footer">
        <button class="button" (click)="closeModal(false)">Nee</button>
        <button class="button-secondary" (click)="closeModal(true)">Ja</button>
    </div>
</div>
