import { Customer } from './customer';
import { Team } from './team';

export class User {

    public id: number = null;
    public first_name: string = null;
    public last_name: string = null;
    public email: string = null;
    public is_admin: boolean = null;
    public is_cockpit_user: boolean = null;
    public is_coordinator: boolean = null;
    public locale: string = null;
    public phone: string = null;
    public teams: Team[] = [];
    public customers: Customer[] = null;

    get name() {
        return `${this.first_name} ${this.last_name}`;
    }

    get roles() {
        const roles = [];
        if (this.is_admin) {
            roles.push('Admin');
        }

        this.teams.forEach((team) => {
            if (team.roles.is_user) {
                roles.push('Teamlid');
            }

            if (team.roles.is_cockpit_user && !team.roles.is_user && !team.roles.is_coordinator) {
                roles.push('Cockpit User');
            }

            if (team.roles.is_coordinator) {
                roles.push('Coordinator');
            }
        });

        const uniqueRoles = [...new Set(roles)];

        return uniqueRoles.join(', ');
    }

    get teamNames() {
        return this.teams.map(team => team.name).join(', ');

    }
}
