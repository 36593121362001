import {
    Injectable,
    Injector,
    ComponentFactoryResolver,
    EmbeddedViewRef,
    ApplicationRef
} from '@angular/core';

export interface ComponentObject {
    type: 'class' | 'id';
    reference: string;
}

@Injectable({
    providedIn: 'root'
})
export class DomService {

    private childComponentRef: any;
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) { }

    public appendComponentTo(parentId: string, child: any) {
        // Create a component reference from the component
        const childComponentRef = this.componentFactoryResolver
            .resolveComponentFactory(child)
            .create(this.injector);

        this.childComponentRef = childComponentRef;
        // Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(childComponentRef.hostView);

        // Get DOM element from component
        const childDomElem = (childComponentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        // Append DOM element to the body
        document.getElementById(parentId).appendChild(childDomElem);

        return childComponentRef;
    }

    public addClassTo(componentObj: ComponentObject, componentClass: string): void {
        if (componentObj.type === 'class') {
            const list = Array.from(document.getElementsByClassName(componentObj.reference));

            list.forEach(element => {
                element.classList.add(componentClass);
            });
        } else {
            document.getElementById(componentObj.reference).classList.add(componentClass);
        }
    }

    /**
     * Set style properties on a component either by id or class. Use camelCase instead of kebab-case properties
     * Wrong:   justify-content
     * Correct: justifyContent
     */
    public setStyleOn(componentObj: ComponentObject, styles: {[key: string]: string}) {
        if (componentObj.type === 'class') {
            const  list = Array.from(document.getElementsByClassName(componentObj.reference));

            list.forEach((element: HTMLElement) => {
                for (const prop in styles) {
                    if (styles.hasOwnProperty(prop)) {
                        element.style[prop] = styles[prop];
                    }
                }
            });
        } else {
            const element = document.getElementById(componentObj.reference);
            for (const prop in styles) {
                if (styles.hasOwnProperty(prop)) {
                    element.style[prop] = styles[prop];
                }
            }
        }
    }

    public removeComponent() {
        if (this.childComponentRef) {
            this.appRef.detachView(this.childComponentRef.hostView);
        }
        this.childComponentRef.destroy();
    }

}
