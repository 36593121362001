<div
    class="factor-row option"
    [class]="color"
    *ngFor="let option of options"
    [ngClass]="{selected: isSelected(option)}"
    [class.disabled]="disable"
    (click)="onOptionClick(option)"
>
        <span class="circle-wrapper">
            <span class="circle"></span>
        </span>
        {{ getOptionLabel(option) }}
</div>
