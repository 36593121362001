import { Injectable } from '@angular/core';
import { share } from 'rxjs/operators';
import { Observable } from 'rxjs';

export class Loader {
    prefix: string;
    key: string;
    active: boolean;

    constructor(prefix = 'LoaderService', key = '', active = false) {
        this.prefix = prefix;
        this.key = key;
        this.active = active;
    }
}

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    private loaders: Array<Loader> = [];

    isLoading(prefix = 'LoaderService') {
        return this.loaders.filter((item: Loader) => (item.prefix === prefix && item.active === true)).length > 0;
    }

    spin(observable: Observable<any>, prefix = 'LoaderService'): Observable<any> {
        const d = new Date();
        const n = d.getTime().toString();
        this.start(n, prefix, 300);
        observable = observable.pipe(share());
        observable
            .subscribe(
                    () => this.stop(n, prefix),
                    () => this.stop(n, prefix)
            );
        return observable;
    }

    start(key = '', prefix = 'LoaderService', delay = 0) {
        this.loaders.push(new Loader(prefix, key));

        // Delay loader start to prevent flickering
        setTimeout(() => {
            this.loaders = this.loaders.map((item: Loader) => {
                if (item.prefix === prefix && item.key === key) {
                    item.active = true;
                }
                return item;
            });
        }, delay);
    }

    stop(key = '', prefix = 'LoaderService') {
        this.loaders = this.loaders.filter((item: Loader) => !(item.prefix === prefix && item.key === key));
    }
}
