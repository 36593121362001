import { User } from '@shared/models/user';
import { MeasurementTemplate } from '@shared/models/measurement-template';
import { Customer } from '@shared/models/customer';

export interface TeamRoles {
    is_user: boolean;
    is_cockpit_user: boolean;
    is_coordinator: boolean;
}

export class Team {
    public id: number = null;
    public name: string = null;
    public customer_id: number = null;
    public users_count: number = null;
    public measurement_templates_count: number = null;
    public results_count: number = null;
    public coordinator: User = null;
    public coordinators: User[] = [];
    public users: User[] = null;
    public measurement_templates: MeasurementTemplate[] = [];
    public roles: TeamRoles = { is_user: false, is_cockpit_user: false, is_coordinator: false };
    public lastMeasurement: MeasurementTemplate;
    public previousMeasurement: MeasurementTemplate | null;
    public selected?: boolean;
    public is_coordinator: boolean;
    public is_user: boolean;
    public color: string;
    public pivot: UserTeamPivot;
    public customer: Customer;
    public can_manage_users: boolean;
    public archived_at: string = null;

    public isCoordinator(user: User) {
        return this.coordinators.filter(coordinator => coordinator.id === user.id).length > 0;
    }
}

interface UserTeamPivot {
    user_id: number;
    team_id: number;
    is_cockpit_user: boolean;
    is_coordinator: boolean;
    is_user: boolean;
}
