<div class="question-edit sidebar-container prompt" *ngIf="question && model && template">
        <div class="body" [formGroup]="model">
            <div class="form-group">
                <label>Type</label>
                <p>{{ type }}</p>
            </div>
            <div class="form-group">
                <label>Stelling</label>
                <textarea name="name"
                          class="form-control"
                          autosize
                          placeholder="Schrijf hier het concrete gedrag op."
                          formControlName="name"
                ></textarea>
            </div>
            <div class="form-group" *ngIf="isScale()">
                <label>Verdeling</label>
                1 - 9
            </div>
            <div class="form-group" *ngIf="isScale() && canBeNegative()">
                <label>Stelling negatief gesteld</label>
                <mat-slide-toggle formControlName="is_reverse"></mat-slide-toggle>
            </div>
            <div class="form-group" *ngIf="isScale() && canBeNegative()">
                <label>Label bij hoge score</label>
                <input name="text_positive" class="form-control" formControlName="text_positive">
            </div>
            <div class="form-group" *ngIf="isScale() && canBeNegative()">
                <label>Label bij lage score</label>
                <input name="text_negative" class="form-control" formControlName="text_negative">
            </div>
            <div class="form-group" *ngIf="isScale() && isReversed">
                <label>Output tekst stelling</label>
                <textarea name="text_output"
                          class="form-control"
                          autosize
                          formControlName="text_output"
                ></textarea>
            </div>
        </div>
        <div class="footer">
            <button class="icon-button" (click)="save()" [attr.disabled]="saveDisabled() ? '' : null"><span class="icon icon-check_boxed"></span></button>
        </div>

</div>
