import { Component } from '@angular/core';
import { BaseModalComponent } from '@shared/components/base-modal/base-modal.component';
import { ImageCroppedEvent, } from 'ngx-image-cropper';

@Component({
    selector: 'app-image-crop-modal',
    templateUrl: 'image-crop-modal.component.html',
    styleUrls: ['image-crop-modal.component.scss'],
})

export class ImageCropModalComponent extends BaseModalComponent {
    imageChangedEvent: any = '';
    croppedImage: any = null;
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = false;
    containWithinAspectRatio = false;

    constructor() {
        super();
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded() {
        this.showCropper = true;
    }

    loadImageFailed() {
        console.log('Load failed');
    }

    deleteLogo() {
        this.croppedImage = null;
        this.fileChangeEvent(null);
    }

    closeModal() {
        this._afterClosed.next(null);
    }

    useLogo() {
        fetch(this.croppedImage).then((res) => res.blob()).then((blob) => {
            const file = new File([blob], 'photo', { type: 'image/png' });
            this._afterClosed.next(file);
        });
    }

}
