import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChartPermissions } from '@shared/models/measurement-template';


@Component({
    selector: 'app-single-select',
    templateUrl: 'single-select.component.html',
    styleUrls: ['single-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SingleSelectComponent),
            multi: true
        }
    ]
})
export class SingleSelectComponent implements OnInit, ControlValueAccessor {
    @Input() options: string[];
    @Input() current: string;
    @Input() title = '';

    public onModelChange = (val) => {
        this.current = val;
    }

    public onModelTouched = () => {
    }

    keepOrder = (a, b) => {
        return a;
    }

    constructor() {
    }

    writeValue(value: any): void {
        this.current = value;
    }

    registerOnChange(fn): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn): void {
        this.onModelTouched = fn;
    }

    onOptionSelect(option) {
        this.current = option;
        this.onModelChange(option);
    }

    ngOnInit() {
    }
}
