<div (mouseover)="showDropdown()" (mouseout)="hideDropdown()" #ref>
    <div><ng-content></ng-content></div>
</div>
<div *ngIf="!disabled" #dropdownComp class="dropdown-menu" (mouseover)="removeHideTimer()" (mouseout)="hideDropdown()">
    <div class="dropdown-menu-options" #menuoptions>
        <div *ngFor="let option of filteredOptions" class="option" (click)="onOptionSelect($event, option.route)">
            <span *ngIf="option.icon" class="option-icon" [ngClass]="option.icon">
                <fa-icon [icon]="['far', option.icon]"></fa-icon>
            </span>
            <span>{{ option.label | translate }}</span>
        </div>
    </div>
</div>
