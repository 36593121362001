import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class ColorService {

    private colors = {
        'apple-green': {
            600: '#4B6F0A',
            500: '#7BA517',
            400: '#95C020',
            300: '#B8D951',
            200: '#D2EC76',
            100: '#F5FBD1'
        },
        green: {
            600: '#226B3A',
            500: '#4F9F5A',
            400: '#6DB971',
            300: '#95D593',
            200: '#B7EAB0',
            100: '#EDFBE6'
        },
        blue: {
            600: '#203967',
            500: '#4A6E99',
            400: '#668DB2',
            300: '#8db3d0',
            200: '#ACD1E7',
            100: '#E5F5FB'
        },
        purple: {
            600: '#291E60',
            500: '#55458E',
            400: '#705FA6',
            300: '#9A88C9',
            200: '#BAA9E4',
            100: '#ECE5FA'
        },
        magenta: {
            600: '#56165E',
            500: '#8C3385',
            400: '#A34693',
            300: '#C772B1',
            200: '#E395C7',
            100: '#FADDEA'
        },
        pink: {
            600: '#72095E',
            500: '#AA156F',
            400: '#C61D72',
            300: '#DC4F88',
            200: '#ED7498',
            100: '#FCD1D5'
        },
        neutral: {
            600: '#252525',
            500: '#4F4F4F',
            400: '#808080',
            300: '#B2B2B2',
            200: '#D8D8D8',
            100: '#F8F8F8'
        },
        error: {
            900: '#7A0B2C',
            800: '#93122E',
            700: '#B71D31',
            600: '#DB2A33',
            500: '#FF413A',
            400: '#FF7D6B',
            300: '#FFA188',
            200: '#FFC7B0',
            100: '#FFE6D7'
        }
    };

    constructor() { }

    getColor(color: string, weight: number = 400, type: 'hex' | 'rgb' | 'rgba' = 'hex', alpha = 1) {
        if (type === 'rgb') {
            return this.hexToRgb(this.colors[color][weight]);
        }
        if (type === 'rgba') {
            return this.hexToRgba(this.colors[color][weight], alpha);
        }
        return this.colors[color][weight];
    }

    getRandomColor(exclude = []) {
        const basisColors = ['apple-green', 'green', 'blue', 'magenta', 'pink', 'purple'];
        const colors = basisColors.filter(color => !exclude.includes(color));
        return colors[Math.floor(Math.random() * colors.length)];
    }

    hexToRgb(hex: string) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})` : null;
    }

    hexToRgba(hex: string, alpha: number) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})` : null;
    }
}
