import { Component, OnInit } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Component({
    selector: 'app-base-modal',
    templateUrl: 'base-modal.component.html',
})

export class BaseModalComponent implements OnInit {
    /** Subject for notifying the user that the dialog has finished opening. */
    protected readonly _afterOpened = new Subject<void>();

    /** Subject for notifying the user that the dialog has finished closing. */
    protected readonly _afterClosed = new Subject<any | undefined>();

    /** Subject for notifying the user that the dialog has started closing. */
    protected readonly _beforeClosed = new Subject<any | undefined>();

    constructor() { }

    ngOnInit() { }

    afterClosed(): Observable<any | undefined> {
        return this._afterClosed.asObservable();
    }
}
