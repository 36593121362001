import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@environments/environment';
import { User } from '../models/user';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public user: BehaviorSubject<User> = new BehaviorSubject(null);

    constructor(
        private http: HttpClient,
        private router: Router
    ) {
        const serviceUser = this.user.getValue();
        const localStorageUser = JSON.parse(localStorage.getItem('user'));

        if (!serviceUser && localStorageUser) {
            this.user.next(plainToClass(User, localStorageUser));
        }
    }

    login(email: string, password: string) {
        const url = `${environment.api_url}/auth/login`;

        return this.http.post(url, {
                email,
                password
            }).pipe(
                tap((response: {token: string, user: User}) => {
                    const user = response.user;
                    localStorage.setItem('accessToken', response.token);
                    localStorage.setItem('user', JSON.stringify(user));
                    this.user.next(plainToClass(User, response.user));
                })
            );
    }

    me() {
        const url = `${environment.api_url}/auth/me`;

        return this.http.get(url).pipe(
            tap((response: {user: User}) => {
                localStorage.setItem('user', JSON.stringify(response.user));
                this.user.next(plainToClass(User, response.user));
            })
        );
    }

    findPasswordReset(token: string) {
        const url = `${environment.api_url}/auth/password/find/${token}`;

        return this.http.get(url);
    }

    getConfig() {
      }

    resetPassword(email: string, token: string, password: string, password_confirmation: string) {
        const url = `${environment.api_url}/auth/password/reset`;

        return this.http.post(url, {
            email,
            token,
            password,
            password_confirmation
        }).pipe(
            tap((response: {token: string, user: User}) => {
                localStorage.setItem('accessToken', response.token);
                localStorage.setItem('user', JSON.stringify(response.user));
                this.user.next(plainToClass(User, response.user));
            })
        );
    }

    isAuthenticated() {
        const token = localStorage.getItem('accessToken');

        if (token) {
            return true;
        } else {
            return false;
        }
    }

    logout() {
        this.user.next(null);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        localStorage.removeItem('cockpit-selected');
        this.router.navigateByUrl('/login');
    }

    forgotPassword(email: string) {
        const url = `${environment.api_url}/auth/password/forgot`;

        return this.http.post(url, {
            email
        });
    }

    private handleError(error: HttpErrorResponse) {
        console.log('errror');

        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong.
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        return throwError(
          'Something bad happened; please try again later.');
      }
}

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(
        private auth: AuthService,
        private router: Router
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.auth.isAuthenticated()) {
            this.router.navigateByUrl('/login');
            return false;
        }

        return true;
    }
}
