import { Injectable, ComponentRef } from '@angular/core';
import { DomService } from './dom.service';
import { BaseModalComponent } from '@shared/components/base-modal/base-modal.component';
import { BehaviorSubject } from 'rxjs';

export interface ModalOptions {
    styles: {[key: string]: string};
}

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    private modalElementId = 'modal-container';
    private overlayElementId = 'overlay';
    public modalOpen = false;
    private options: ModalOptions = {
        styles: {
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent'
        }
    };

    public modalData = new BehaviorSubject<any>(null);

    constructor(private domService: DomService) { }

    init(component: any, data: any, modalOptions?: ModalOptions): ComponentRef<BaseModalComponent> {
        this.modalData.next(data);
        let options = this.options;
        if (modalOptions) {
            options = Object.assign({}, options, modalOptions);
        }
        const componentRef = this.domService.appendComponentTo(this.modalElementId, component);
        document.getElementById(this.modalElementId).className = 'show';
        document.getElementById(this.overlayElementId).className = 'show';

        this.domService.setStyleOn({ type: 'id', reference: this.modalElementId }, options.styles);
        this.modalOpen = true;
        return componentRef as ComponentRef<BaseModalComponent>;
    }

    destroy() {
        this.modalOpen = false;
        this.domService.removeComponent();
        document.getElementById(this.modalElementId).className = 'hidden';
        document.getElementById(this.overlayElementId).className = 'hidden';
    }
}
