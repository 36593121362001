<div class="shadow-md p-5 bg-white rounded flex flex-col justify-between items-center space-y-4">
    <div>
        <h2 class="text-center text-lg">{{title}}</h2>
    </div>
    <div *ngIf="body" class="body" [innerHTML]="body">
    </div>
    <div class="flex gap-2">
        <button *ngIf="confirm" class="button button-pink" (click)="closePrompt(true)">{{confirm}}</button>
        <button *ngIf="deny" class="button button-outline" (click)="closePrompt(false)">{{deny}}</button>
    </div>
</div>
