import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Team } from '../models/team';
import { LoaderService } from './loader.service';
import { HttpClient } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TeamService {
    private url = `${environment.api_url}/teams`;
    private measurementTemplatesUrl = `${environment.api_url}/measurement-templates`;

    public teams: BehaviorSubject<Team[]> = new BehaviorSubject([]);
    public isEditing: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public activeTeam: BehaviorSubject<Team> = new BehaviorSubject(null);

    constructor(
        private loaderService: LoaderService,
        private http: HttpClient
    ) { }

    public fetch(customerId?) {
        this.loaderService.start();

        let url = `${environment.api_url}/teams`;

        if (customerId) {
            url = `${environment.api_url}/customers/${customerId}/teams`;
        }

        this.http.get<{teams: Team[]}>(url)
            .pipe(
                map(response => response.teams),
                map(team => plainToClass(Team, team))
            )
            .subscribe(
                (teams) => {
                    this.teams.next(teams);
                    this.loaderService.stop();
                },
                () => { this.loaderService.stop(); }
            );
    }

    public fetchTeam(id) {
        this.loaderService.start();
        this.getTeam(id)
            .subscribe(
                team => {
                    this.activeTeam.next(team);
                    this.loaderService.stop();
                },
                () => { this.loaderService.stop(); }
            );
    }

    public getTeam(id) {
        return this.http.get<{team: Team}>(`${this.url}/${id}`)
            .pipe(
                map(response => {
                    this.activeTeam.next(plainToClass(Team, response.team));
                    return response.team;
                }),
                map(team => plainToClass(Team, team)),
        );
    }

    public save(teamId, model) {
        if (model.type === 'small') {
            if (model.id) {
                return this.http.put(`${this.measurementTemplatesUrl}/${model.id}/small`, model);
            } else {
                return this.http.post(`${this.url}/${teamId}/measurement-templates/relate/small`, model);
            }
        } else {
            if (model.id) {
                return this.http.put(`${this.measurementTemplatesUrl}/${model.id}/big`, model);
            } else {
                return this.http.post(`${this.url}/${teamId}/measurement-templates/relate/big`, model);
            }
        }
    }

    public saveNext(teamId, model) {
        if (model.type === 'small') {
            if (model.id) {
                return this.http.put(`${this.measurementTemplatesUrl}/${model.id}/small/next`, model);
            }
        }
    }

    public deleteMeasurement(teamId, measurementId) {
        return this.http.delete(`${this.url}/${teamId}/measurement-templates/${measurementId}`);
    }

    public archive(teamId) {
        return this.http.delete(`${this.url}/${teamId}/archive`);
    }

    public unarchive(teamId) {
        return this.http.post(`${this.url}/${teamId}/unarchive`, {});
    }
}
