<div class="image-cropper">
    <div class="modal-header">{{ 'CUSTOMERS.COMPANY_LOGO' | translate }}<span class="icon icon-x" (click)="closeModal()"></span></div>
    <div class="modal-body">
        <input #logofile type="file" (change)="fileChangeEvent($event)" />
        <image-cropper
            class="border-box"
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [containWithinAspectRatio]="containWithinAspectRatio"
            [aspectRatio]="1 / 1"
            [cropperMinWidth]="160"
            [onlyScaleDown]="true"
            [roundCropper]="false"
            [alignImage]="'left'"
            [style.display]="showCropper ? null : 'none'"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
    </div>
    <div class="modal-footer">
        <button class="button" (click)="logofile.click();">{{ 'CUSTOMERS.UPLOAD_LOGO' | translate }}</button>
        <button class="button-secondary" (click)="useLogo();" [attr.disabled]="croppedImage ? null : ''">{{ 'CUSTOMERS.SAVE_LOGO' | translate }}</button>
    </div>
</div>
