<div class="permissions-select" *ngIf="customer">
    <div class="form-group">
        <label>{{title}}</label>
    </div>
    <ul>
        <li class="permission" *ngFor="let permission of permissions | keyvalue: keepOrder">
            <div class="d-flex label"
                 [class.selected]="permission.value"
                 [class.disabled]="disabledByKey(permission.key)"
                 (click)="onPermissionClick(permission)"
            >
                <span class="circle-wrapper">
                    <span class="circle"></span>
                </span>
                <span class="key">
                    {{labelByKey(permission)}}
                </span>
            </div>
        </li>
    </ul>

    <ul *ngIf="selectAllEnabled">
        <button type="button" class="rounded button-pink" (click)="selectAll()">
            {{ (allSelected ? 'CUSTOMERS.TEAMS.REPORTS.LOCK_ALL' : 'CUSTOMERS.TEAMS.REPORTS.RELEASE_ALL') | translate }}
        </button>
    </ul>
</div>
