<div class="prompt">
    <div class="modal-header">{{ 'GENERAL.ARCHIVE_QUESTION' | translate }}<span class="icon icon-x" (click)="closeModal(false)"></span></div>
    <div class="modal-body">
        {{ 'GENERAL.ARCHIVE_QUESTION_BODY' | translate }}
    </div>
    <div class="modal-footer">
        <button class="button" (click)="closeModal(false)">{{ 'GENERAL.NO' | translate }}</button>
        <button class="button-secondary" (click)="closeModal(true)">{{ 'GENERAL.YES' | translate }}</button>
    </div>
</div>
