import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-sidebar-tab',
    styleUrls: ['./sidebar-tab.component.scss'],
    template: `
        <div class="tab" [hidden]="!active">
            <ng-content></ng-content>
        </div>
    `
})
export class SidebarTabComponent {
    @Input() tabTitle: string;

    public active = false;

    constructor(tabs: SidebarTabsComponent) {
        tabs.addTab(this);
    }
}

@Component({
    selector: 'app-sidebar-tabs',
    styleUrls: ['./sidebar-tabs.component.scss'],
    template: `
        <ul class="tabs">
            <li *ngFor="let tab of tabs" (click)="selectTab(tab)" [ngClass]="{'active': tab.active}">
                {{tab.tabTitle}}
            </li>
        </ul>
        <ng-content></ng-content>
    `,
})
export class SidebarTabsComponent {
    @Output() tabChange = new EventEmitter<string>();

    public tabs: SidebarTabComponent[] = [];

    selectTab(sidebarTab: SidebarTabComponent) {
        this.tabs.forEach(tab => {
            tab.active = false;
        });
        sidebarTab.active = true;
        this.tabChange.emit(sidebarTab.tabTitle);
    }

    addTab(tab: SidebarTabComponent) {
        if (this.tabs.length === 0) {
            tab.active = true;
        }
        this.tabs.push(tab);
    }
}
