import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface ChangeEvent {
    value: any;
    itemValue: any;
}

@Component({
    selector: 'app-custom-factor-select',
    templateUrl: 'custom-factor-select.component.html',
    styleUrls: ['custom-factor-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomFactorSelectComponent),
            multi: true
        }
    ]
})
export class CustomFactorSelectComponent implements OnInit, ControlValueAccessor {
    @Input() options: any[] = [];
    @Input() color: string;
    @Input() multiple = false;
    @Input() optionLabel;
    @Input() dataKey = 'value';
    @Input() disable: false;

    public value: any[] = [];
    public onModelChange = (val) => {};
    public onModelTouched = () => {};

    constructor() { }

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn): void {
        this.onModelTouched = fn;
    }

    getOptionLabel(option) {
        if (this.optionLabel instanceof Array) {
            let optionTitle = '';
            this.optionLabel.forEach((item) => optionTitle += `${option[item]} `);

            return optionTitle;
        }
        return option[this.optionLabel];
    }

    getOptionValue(option) {
        return option[this.dataKey];
    }

    onOptionClick(option) {
        if (this.disable) {
            return;
        }
        const optionValue = this.getOptionValue(option);

        if (this.multiple) {
            if (this.isSelected(option)) {
                const index = this.value.indexOf(optionValue);
                this.value.splice(index, 1);
            } else {
                this.value = [...this.value || [], optionValue];
            }
        } else {
            this.value = [optionValue];
        }

        this.onModelChange(this.value);
    }

    isSelected(option) {
        if (!this.value) {
            return false;
        }
        const optionValue = this.getOptionValue(option);
        return this.value.indexOf(optionValue) !== -1;
    }

    ngOnInit() { }
}
